export default [
  // {
  //   header: 'Pages',
  //   icon: 'FileIcon',
  //   children: [
  //     {
  //       title: 'Home',
  //       route: 'home',
  //       icon: 'HomeIcon',
  //     },
  //     {
  //       title: 'Second Page',
  //       route: 'second-page',
  //       icon: 'FileIcon',
  //     },
  //   ],
  // },
  {
    title: 'My Accounts',
    route: 'my-accounts',
    icon: 'FolderIcon',
  },
  {
    title: 'Transactions',
    route: 'transactions',
    icon: 'DollarSignIcon',
  },
  // {
  //   title: 'Profile',
  //   route: 'profile',
  //   icon: 'UserIcon',
  // },
]
